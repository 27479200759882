/** @format */

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default function useInvoicesList(proxy) {
  const toast = useToast()
  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'actions',
      label: '操作',
      thStyle: {
        width: '30px',
        textAlign: 'center',
      },
    },
    { key: 'avatar', label: '头像' },
    { key: 'id', label: '#' },
    { key: 'firstname', label: '名', formatter: val => `${val}` },
    { key: 'lastname', label: '姓', formatter: val => `${val}` },
    { key: 'gender', label: '性别' },
    { key: 'mobile', label: '手机号' },
    { key: 'email', label: '邮箱' },
    { key: 'current_level', label: '等级' },
    { key: 'money', label: '佣金' },
    { key: 'all_tp', label: 'tp' },
    {
      key: 'pid',
      label: '推荐人',
      thStyle: {
        width: '120px',
        textAlign: 'center',
      },
    },
    { key: 'address', label: '家庭住址' },
  ]
  const tablefile = [
    { key: 'id', label: '#' },
    { key: 'real_name', label: '姓名', formatter: val => `${val}` },
    { key: 'mobile', label: '手机号' },
    { key: 'email', label: '邮箱' },
    { key: 'reason', label: '状态' },
  ]
  const perPage = ref(100)
  const page_size = ref(100)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 50, 100]
  const searchQuery = ref('')
  const searchId = ref('')

  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, searchId], () => {
    page_size.value = perPage.value
    refetchData()
  })
  // 查询全部数据
  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('vip-module/fetchInvoices', {
        page_size: page_size.value,
        page: currentPage.value,
        name: searchQuery.value,
        id: searchId.value,
      })
      .then(response => {
        const { list, count, page_size } = response.data
        callback(list)
        totalInvoices.value = count
      })
  }
  const tablelist = (ctx, callback) => {
    store
      .dispatch('vip-module/tablelist', {
        page_size: page_size.value,
        page: currentPage.value,
        name: searchQuery.value,
        id: searchId.value,
      })
      .then(response => {
        const { list, count, page_size } = response.data
        callback(list)
        totalInvoices.value = count
        perPage.value = page_size
      })
  }
  // 数据删除
  const confirmDelete = val => {
    store
      .dispatch('vip-module/deleteInvoices', val)
      .then(res => {
        toast({
          component: ToastificationContent,
          props: {
            title: res.msg || '删除成功',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.msg || '删除错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  return {
    fetchInvoices,
    tableColumns,
    perPage,
    tablefile,
    tablelist,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    searchId,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,
    statusFilter,
    refetchData,
    confirmDelete,
  }
}
