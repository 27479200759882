/** @format */

import axios from '@axios'

export default {
  namespaced: true,
  state: {
    vipDetail: {},
  },
  getters: {},
  mutations: {
    setVipDetail(state, payload) {
      state.vipDetail = payload
    },
  },
  actions: {
    // 查询全部数据
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // 删除数据
    deleteInvoices(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/deleteUser', { params: { id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // 修改数据
    editInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/editUser', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/addUser', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
