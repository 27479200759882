<!-- @format -->

<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>每页条数</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button variant="primary" :to="{ name: 'apps-member-create' }">
            添加
          </b-button>
          <b-button
            variant="primary"
            @click="$bvModal.show('audit-modal')"
            style="margin-left: 20px"
          >
            导入
          </b-button>
        </b-col>
        <b-modal
          id="audit-modal"
          cancel-variant="outline-secondary"
          ok-title="确认"
          cancel-title="取消"
          centered
          title="文件导入"
          :no-close-on-backdrop="true"
        >
          <b-form>
            <b-form-group>
              <input ref="refInputEl" type="file" @input="inputImageRenderer">
            </b-form-group>
          </b-form>
          <!--   footer   -->
          <b-table
            ref="refInvoiceListTable"
            :items="tableData.list"
            responsive
            :fields="tablefile"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="暂无数据"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
            bordered
            style="vertical-align: middle !important; text-align: center"
          />
          <template #modal-footer>
            <b-button
              variant="secondary"
              @click.prevent="$bvModal.hide('audit-modal')"
            >取消</b-button>
          </template>
        </b-modal>
        <!-- Search -->
        <b-col cols="12" md="3">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="查询姓名..."
            />
          </div>
        </b-col>
        <b-col cols="12" md="3">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchId"
              class="d-inline-block mr-1"
              placeholder="查询ID..."
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="暂无数据"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      bordered
      style="vertical-align: middle !important; text-align: center"
    >
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="d-flex align-items-center">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click.prevent="setVipDetail(data.item)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>编辑</span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>删除</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template #cell(avatar)="data">
  <b-avatar :src="data.item.avatar.includes('api') ? data.item.avatar : 'https://api.myonewow.com/' + data.item.avatar" />
</template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
// import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { getCurrentInstance, reactive, ref } from '@vue/composition-api'
import store from '@/store'
import VipManagerModule from '@/views/GeneralManagement/VipManager/List/VipManagerModule'
import router from '@/router'
import axiosIns from '@/libs/axios'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-file'
import useInvoicesList from './memberList'

export default {
  components: {
    BAvatar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const tableData = reactive({
      list: []
    })
    const INVOICE_APP_STORE_MODULE_NAME = 'vip-module'
    const refInputEl = ref(null)
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) { store.registerModule(INVOICE_APP_STORE_MODULE_NAME, VipManagerModule) }

    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]
    const inputImageRenderer = $event => {
      const { target } = $event
      const formData = new FormData()
      formData.append('file', target.files[0])
      // console.log(formData)
      axiosIns.post('/user/importUser', formData).then(res => {
        tableData.list = res.data.err_data
        if (res.code == -1) {
        // $toast(res.msg)
          proxy.$bvToast.toast(res.msg)
        }
      })
    }
    // const { inputImageRenderer } =

    const setVipDetail = data => {
      data.gender = data.gender == '男' ? 1 : 2
      store.commit(`${INVOICE_APP_STORE_MODULE_NAME}/setVipDetail`, data)
      setTimeout(() => {
        router.push({
          path: '/general/member/member_list/edit',
          query: {
            id: data.id,
          },
        })
      }, 500)
    }
    const {
      fetchInvoices,
      tableColumns,
      perPage,
      tablefile,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchId,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      statusFilter,
      confirmDelete,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      filetable,
      tablelist
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      tablefile,
      tablelist,
      tableData,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchId,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      setVipDetail,
      statusFilter,
      refetchData,
      statusOptions,
      confirmDelete,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      inputImageRenderer,
      refInputEl,
      filetable
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
